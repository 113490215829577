.projects-container {
  margin-top: 50px;
  color: rgb(1, 1, 2);
  min-height: 100vh;
}

/* .footer-image {
  width: 100%;
  height: 100%;
  max-width: 1920px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  margin-top: -170px;
}
.footer-image img {
  max-width: 100%;
  height: 195px;
} */

/* .testimonial-section {
} */

.row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 10px;
  margin: 10px;
}

.card {
  border-radius: 20px;
  min-height: 100%;
  border: 1px solid var(--gold);
}

.card-item {
  background-color: rgb(167, 118, 118);
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 20px 40px -14px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  transition: transform 0.5s;
  -webkit-transition: transform 0.5s;
}

/* // Content */
.card-info {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 1rem;
  line-height: 1.5em;
}

.card-title {
  font-size: 18px;
  line-height: 1.1em;
  color: #32325d;
  margin-bottom: 0.2em;
}

.card-title i {
  font-size: 20px;
  line-height: 1.1em;
  color: #32325d;
  margin-bottom: 0.2em;
}

/* // Images */
.card-image {
  height: 200px;
  width: 350px;
  overflow: hidden;
  border-radius: 6px 6px 0px 0px;
  opacity: 0.91;
}

.card-intro {
  font-size: 12px;
}

/* --------------- 390px ------------------- */
@media only screen and (max-width: 390px) {
  .row {
    grid-template-columns: 1fr;
  }

  .footer-image {
    width: 100%;
    height: 100%;
    max-width: 1920px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    margin-top: -90px;
  }
  .footer-image img {
    max-width: 100%;
    height: 100px;
  }
}
/* --------------- END 390px ------------------- */

/* --------------- 828px ------------------- */

@media only screen and (max-width: 828px) {
  .row {
    grid-template-columns: 1fr 1fr;
  }
}
/* --------------- END 828px ------------------- */

/* --------------- 1125px ------------------- */
@media only screen and (max-width: 1125px) {
}
/* --------------- END 1125px ------------------- */

/* --------------- 1180px ------------------- */

@media only screen and (max-width: 1180px) {
  .row {
    grid-template-columns: 1fr 1fr;
  }
}
/* --------------- END 1180px ------------------- */

/* --------------- 670px ------------------- */
@media only screen and (max-width: 670px) {
  .row {
    grid-template-columns: 1fr;
  }
}

/* --------------- END 670px ------------------- */

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  max-width: 330px;
  margin: auto;
  text-align: center;
  font-family: arial;
}

a {
  text-decoration: none;
  font-size: 22px;
  color: black;
}
