:root {
  --dark-blue: #1f2235;
  --light-blue: #04c3ff;
  --light-blue-shadow: rgb(69 211 255 / 27%);
  --sea-green: #0abead;
  --sea-green-dark: #0cbfae;
  --sea-green-extra-dark: #09bead;
  --sea-green-shadow: rgba(11, 190, 173, 0.4);
  --header-hamburger-background: rgba(11, 190, 173, 0.8);
  --orange: #f57f17;
  --dark-orange: #ff5823;
  --gold: #c7a03b;
  --orange-shadow: rgba(245, 127, 23, 0.2);
  --white: #ffffff;
  --black: #333333;
  --disabled-btn: #09bead7a;
  --error-message: #e44f4fd1;
  --toast-error: #e44f4ffa;
  --toast-shadow: #9c9c9ce3;
  --toast-success: #0cbfae;
  --loader-color: #0cbfae;
}

.icon-light-color {
  color: var(--gold);
}

.btn-scroll-up {
  display: none;
  position: fixed;
  z-index: 99;
  outline: none;
  cursor: pointer;

  bottom: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  color: var(--gold);
  margin: 0px 10px 10px 0px;
  font-size: 54px;
  background-color: var(--white);
  border-radius: 50%;
  box-shadow: 2px 2px 5px var(--gold);
  border: none;
}
.btn-scroll-up i:hover {
  transform: rotate(360deg);
  color: var(--gold);
}

.btn-scroll-up i {
  -webkit-animation: move 0.5s infinite alternate;
  animation: move 0.5s infinite alternate;
}

@media only screen and (max-width: 390px) {
  .btn-scroll-up {
    visibility: hidden;
  }
}

/* --------------- 670px ------------------- */
@media only screen and (max-width: 670px) {
  .btn-scroll-up {
    visibility: hidden;
  }
}
