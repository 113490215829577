.main-container {
  min-height: 100vh;
  margin-top: 150px;
}

.central-form {
  max-width: 1100px;
  padding: 20px;
  display: flex;
  box-shadow: 0 0px 20px -2px var(--gold);
  border-radius: 12px;
  margin: 0 auto;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dark-blue);
  box-shadow: 20px 20px 50px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
}

.central-form h2 {
  color: #fff;
  font-family: "Poppins Bold";
  letter-spacing: 0.2rem;
  /* margin-bottom: 20px; */
}

.back-form {
  max-width: 380px;
  margin: 0 auto;
}

.back-form .img-back {
  width: 100%;
  margin-bottom: 5px;
}

.img-back h4 {
  letter-spacing: 0.3rem;
  line-height: 18.2px;
  font-weight: 400;
  text-align: center;
  color: var(--white);
  font-size: 14px;
}

.icons-container {
  display: flex;
  gap: 20px;
  justify-content: center;
}
.icon-light-color {
  color: var(--gold);
}

.back-form .img-back img {
  width: 100%;
  /* opacity: 0.5; */
}

.central-form form {
  width: auto;
  box-shadow: 0 0 2px 1px white;
  display: flex;
  color: #111;
  padding: 15px;
  justify-content: center;
  flex-direction: column;
  opacity: 0.9;
  background-color: white;
  box-shadow: 0 0px 20px -2px var(--dark-blue);
  border-radius: 12px;
  gap: 5px;
}

form p {
  text-align: center;
  margin-bottom: 10px;
  color: var(--gold);
  letter-spacing: 0.1rem;
  font-family: "Poppins Bold";
}

form label {
  margin-bottom: 5px;
  color: #111;
  letter-spacing: 0.2rem;
}

form input,
textarea {
  width: 100%;
  border: 2px solid rgba(0, 0, 0, 0);
  outline: none;
  background-color: rgba(230, 230, 230, 0.6);
  padding: 0.5rem 1rem;
  font-size: 1.1rem;
  /* margin-bottom: 22px; */
  transition: 0.3s;
  border-radius: 10px;
  box-shadow: inset 1px 1px 1px 1px;
}

textarea:focus {
  border: 2px solid rgba(30, 85, 250, 0.47);
  background-color: #fff;
}
form input:focus {
  border: 2px solid rgba(30, 85, 250, 0.47);
  background-color: #fff;
}

form button {
  margin-top: 15px;
  padding: 5px;
  outline: none;
  width: 900px;
  color: rgb(230, 227, 227);
  border: 2px solid var(--gold);
  font-size: 12px;
  border-radius: 19px;
  background-color: #1f2235;
  font-size: 18px;
  width: 160px;
  align-items: center;
  justify-content: center;
  box-shadow: 3px 3px antiquewhite;
}

form button:hover {
  background-color: var(--gold);
  transition: all ease 1s;
  border: 2px solid var(--dark-blue);
}

button .load {
  width: 100%;
}

button .load img {
  width: 60px;
  color: green;
}
.col i {
  padding: 7px;
  color: var(--gold);
}
.col i:hover {
  transform: scale(1.5);
}

.fa {
  padding: 2px;
}

@media screen and (min-width: 940px) {
  .back-form {
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    /* margin-top: 30px; */
  }

  .img-back {
    flex: 1;
    align-self: center;
    margin-right: 20px;
  }

  form {
    flex: 0.9;
  }

  form input,
  textarea {
    border-radius: 10px;
    box-shadow: inset 1px 1px 1px 1px;
  }
}
@media screen and (max-width: 430px) {
  .central-form {
    margin: 0 10px;
  }

  .central-form h2 {
    font-size: 16px;
  }
  .img-back {
    flex: 1;
    align-self: center;
    margin-right: 20px;
  }
  /* .img-back img {
    margin-bottom: 25px;
  } */

  form input,
  textarea {
    border-radius: 10px;
    box-shadow: inset 1px 1px 1px 1px;
  }
}

@media only screen and (max-width: 1121px) {
  form input,
  textarea {
    border-radius: 10px;
    box-shadow: inset 1px 1px 1px 1px;
  }
}
