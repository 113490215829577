.about-me-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 5;
  padding: 20px;
}

.fade-in {
  opacity: 0;
  transform: translateY(80px);
  transition: all 0.8s ease;
}

.about-me-parent {
  /* max-width: 1000px; */
  width: 90%;
}

.about-me-card {
  display: flex;
  width: 100%;
  box-shadow: 0 0 20px -2px;
  border-radius: 10%;
  box-shadow: 0 0 20px -2px var(--dark-blue);
  background-color: var(--dark-blue);
}

.about-me-profile {
  width: 60%;
  background-image: url("../../assets/Home/emt.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  border-radius: 10% 0% 10% 10%;
}

.about-me-details {
  text-align: justify;
  padding: 40px 70px 20px 20px;
  width: 48%;
}

.about-me-description {
  font-size: 12px;
  color: #ffffff;
  font-weight: 400;
}

.about-me-highlights {
  margin: 40px 0;
  color: var(--gold);
}
.highlight-heading span {
  font-weight: 400;
  font-size: 20px;
}

.highlight {
  display: flex;
  align-items: center;
  margin: 5px 0;
  font-size: 12px;
}

.highlight-blob {
  height: 12px;
  width: 12px;
  margin: 0 10px 0 0;
  background-color: rgb(104, 29, 29);
}

@media only screen and (max-width: 1110px) {
  .about-me-parent {
    width: 88%;
  }
}

@media only screen and (max-width: 1110px) {
  .about-me-options {
    margin-left: -15px;
  }
  .about-me-highlights {
    margin: 80px -15px;
  }
  .highlight-heading span {
    font-weight: 400;
    font-size: 15px;
  }
}

@media only screen and (max-width: 390px) {
  .about-me-profile {
    width: 50%;
    background-image: url("../../assets/Home/emt.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
  }
  .about-me-details {
    width: 100%;
    text-align: justify;
    padding: 30px;
  }
  .about-me-card {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    box-shadow: 0 0 20px -2px black;
    border: 1px solid var(--gold);
  }
  .about-me-highlights {
    margin: 10px 0;
  }
}

@media only screen and (max-width: 430px) {
  .about-me-profile {
    width: 50%;
    background-image: url("../../assets/Home/emt.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
  }
  .about-me-details {
    width: 100%;
    text-align: justify;
    padding: 30px;
  }
  .about-me-card {
    display: flex;
    flex: 1;
    flex-direction: column;
    width: 100%;
    box-shadow: 0 0 20px -2px black;
  }
  .about-me-highlights {
    margin: 10px 0;
  }
}
