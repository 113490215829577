.scroll-container {
  position: absolute;
  text-align: center;
  right: 0;
  margin-right: 9px;
  overflow: hidden;
  flex-direction: column;
  /* margin-top: -50px; */
  z-index: 100;
  cursor: pointer;
}
@media only screen and (min-width: 475px) {
  .scroll-container {
    margin-bottom: 10px;
  }
}
.btn-scroll {
  width: 50px;
  height: 50px;
  color: aliceblue;
  font-size: 24px;
  background-color: tomato;
  border-radius: 10px;
  border: none;
}
.btn-scroll i:hover {
  transform: rotate(360deg);
  color: aliceblue;
}

.btn-scroll i {
  -webkit-animation: move 0.5s infinite alternate;
  animation: move 0.5s infinite alternate;
}
@keyframes mover {
  100% {
    transform: translateY(-5px);
  }
  0% {
    transform: translateX(0px);
  }
}

.footer-container {
  padding: 10px;
  width: 100%;
  height: 100%;
}

.footer-parent {
  padding: 10px;
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: center;
  display: grid;
  grid-template-rows: 1fr;
  font-size: 20px;

  color: black;
}

.colz-icon {
  align-items: center;
  text-align: center;
  justify-content: center;
}

@media only screen and (max-width: 390px) {
  .footer-parent {
    font-size: 10px;
  }
}

@media only screen and (max-width: 670px) {
  .footer-parent {
    font-size: 10px;
  }
}

@media only screen and (max-width: 828px) {
  .footer-parent {
    font-size: 10px;
  }
}
