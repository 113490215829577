.profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 10%;
  gap: 10px;
  min-height: fit-content;
  margin-bottom: 10px;
  padding: 10px;
}

.profile-parent {
  display: flex;
  align-items: center;
  color: aliceblue;
}

.profile-sub-section {
  display: flex;
  flex-wrap: wrap;
  min-width: 100%;
  color: var(--gold);
  justify-content: space-evenly;
  margin: 0;
  padding: 4%;
  text-align: center;
}

.profile-details-name {
  font-size: 24px;
  font-family: "Poppins SemiBold";
}

.profile-details-role {
  display: flex;
  flex-direction: column;
  margin: 14px 0 24px 0;
}

.profile-details-role h1 {
  font-size: 40px;
  font-family: cursive;
}

.primary-text {
  color: (--white);
  opacity: 0.8;
}

.highlighted-text {
  color: var(--gold);
}

.tagline {
  display: flex;
  width: 500px;
  align-items: center;
  justify-content: center;
}
.profile-role-tagline {
  font-size: 14px;
  margin: 5px 10px 0 10px;
  font-family: "Poppins Light";
  /* white-space: normal; */
  white-space: pre-wrap;
  text-align: center;
}

.profile-options .highlighted-btn {
  margin: 0 0 0 28px;
}

.profile-options .highlighted-btn:hover {
  margin: 0 0 0 28px;
  background-color: cornsilk;
  color: #111;
}

.profile-picture {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  box-shadow: 0 1px 0px 0px var(--white);
  border-radius: 20%;
  height: 450px;
  width: 420px;
  margin: 0 30px 0 30px;
}

.profile-picture-background {
  height: 93%;
  width: 80%;
  background-image: url("../../../assets/Home/profile2.png");
  background-size: cover;
  border-radius: 20%;
  background-repeat: no-repeat;
  background-position: center;
}

.profile-picture-background:hover {
  transform: scale(1.07);
  transition: 1s ease-out;
}

.colz-icon {
  display: flex;
  gap: 20px;
}

.colz .colz-icon {
  padding: 8px;
  color: white;
  padding-left: 0px;
}

.colz .colz-icon i:hover {
  transform: scale(1.5);
}

.icon-light-color {
  color: var(--white);
}

.subheading-color {
  color: var(--white);
  opacity: 0.8;
}

@media screen and (max-width: 1110px) {
  .profile-picture {
    margin: 0 20px 10px 20px;
    border-radius: 30%;
  }
  .profile-picture-background {
    border-radius: 30%;
  }
  .profile-details-name {
    font-size: 20px;
  }
  .profile-details-role .primary-text {
    font-size: 14px;
  }
  .profile-details-role h1 {
    font-size: 25px;
  }
  .profile-options .btn {
    width: 120px;
    padding: 11px 0;
    z-index: 700;
  }
  .tagline {
    display: flex;
    width: 300px;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 910px) {
  .profile-picture {
    height: 320px;
    width: 320px;
    margin: 0 0 0 4px;
  }
}

@media screen and (max-width: 820px) {
  .profile-picture {
    margin: 0 !important;
  }
  .profile-parent {
    flex-direction: column-reverse;
    margin: 40px 0 0 0;
  }
  .profile-options {
    display: flex;
    justify-content: space-evenly;
  }
  .profile-details {
    margin: 10px 0 0;
  }
  .profile-options .highlighted-btn {
    margin: 5px !important;
  }
  .profile-options {
    text-align: center;
  }
  .tagline {
    display: flex;
    width: 300px;
    align-items: center;
    justify-content: center;
  }
  .profile-role-tagline {
    font-size: 14px;
    margin: 0px 0px 0 0px;
    font-family: "Poppins Light";
    white-space: normal;
    /* white-space: pre-wrap; */
    text-align: center;
  }
}

@media screen and (max-width: 375px) {
  .profile-parent {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    color: aliceblue;
  }
  .profile-picture {
    height: 275px;
    width: 275px;
    margin-top: -90px;
  }
  .profile-details {
    width: 70%;
  }

  .profile-role-tagline {
    font-size: 14px;
    /* background-color: aliceblue; */
    white-space: pre-wrap;
    text-align: center;
  }

  .profile-details-role h1 {
    font-size: 30px;
    font-family: cursive;
  }

  .tagline {
    display: flex;
    width: 200px;
    align-items: center;
    justify-content: center;
  }

  .profile-details-role h1 {
    font-size: 20px;
  }
}
